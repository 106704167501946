<template>
	<div class="u-flex u-col-top" v-loading="loading">
		<div class="u-flex-col u-flex-1">
			<p class="m-right-title">练习题<el-button class="m-right-title-btn" type="primary" size="small" @click="resetQuestion"><i class="el-icon-refresh"></i>重置</el-button></p>

			<div class="m-con" style="width: 660px;" v-if="currentData.title">
				 
				 
				<template v-if="!isOver">
					<p class="">
						<span class="m-title-label" v-if="currentData.type == 1">【单选题】</span>
						<span class="m-title-label" v-if="currentData.type == 2">【判断题】</span>
						<span class="m-title-label" v-if="currentData.type == 3">【多选题】</span>
						（{{params.page}} / {{count}}） {{currentData.title}}
					</p>

					<div class="m-con-img" v-if="currentData.image">
						<img :src="currentData.image" />
					</div>
					<div class="u-m-t-30 m-content-option">
						<template v-if="currentData.type == 1 || currentData.type == 2">
							<el-radio-group v-model="currentData.answerList">
								<div class="u-m-t-10 {isErr}" v-for="(item,index) in currentData.options">
									<el-radio :disabled="currentData.isAnswer" :label="item.key">{{item.key}} .
										{{item.value}}
									</el-radio>
								</div>
							</el-radio-group>
						</template>
						<template v-if="currentData.type == 3">
							<el-checkbox-group v-model="currentData.answerList">
								<div class="u-m-t-10" v-for="(item,index) in currentData.options">
									<el-checkbox :disabled="currentData.isAnswer" :label="item.key">{{item.key}} .
										{{item.value}}
									</el-checkbox>
								</div>
							</el-checkbox-group>
						</template>
						<template v-if="currentData.type == 4">
							<el-input :autosize="{ minRows: 4, maxRows: 8}" type="textarea" v-model="currentData.answerList" placeholder="请填写您的答案"></el-input>
						</template>
					</div>
					
					<template v-if="currentData.isShow">
						
						<p class="u-m-t-50" v-if="currentData.type != 4">
							<span class="m-font1">【您的答案】</span>
							<span class="m-font2 u-m-l-4">{{formatArr(currentData.answerList)}}</span>
						</p>
						<p class="u-m-t-20" v-if="currentData.type != 4">
							<span class="m-font1">【正确答案】</span>
							<span class="m-font1 u-m-l-4">{{formatArr(currentData.answer)}}</span>
						</p>
						<p :class="{'u-m-t-20':currentData.type != 4,'u-m-t-50':currentData.type == 4}">
							<span class="m-font1">【答案解析】</span>
						</p>
						<div class="u-m-t-5 u-m-l-10 m-font-html" v-html="currentData.analysis"></div>
					</template>
			 
					<div class="u-flex u-row-between u-m-t-60">
						
						<div class="u-flex">
							<span class="m-btn" :class="{'m-dis':1 == params.page}" @click="beforeQuestion">上一题</span>
							<span class="m-btn u-m-l-16" v-if="count > params.page"
								:class="{'m-dis':count <= params.page}" @click="nextQuestion">下一题</span>
							<span class="m-btn u-m-l-16" v-else
								:class="{'m-dis':count == params.page && currentData.isAnswer}"
								@click="nextQuestion2">下一题</span>
						</div>
						<div class="u-flex">
							<span class="m-btn2" @click="showAnswer">答案</span>
							<span class="m-btn2 u-m-l-16"
								@click="collect">{{currentData.is_collect == 1 ? '取消收藏' : '收藏'}}</span>
						</div>
					</div>

				</template>
			</div>
			<div class="m-con u-flex u-row-center" v-else-if="!currentData.title && !loading">
				<img src="../../assets/empty-bg.png" />
			</div>
		</div>
		<div class="m-type-list">
			<el-scrollbar style="height: 560px;overflow-x: 0;" >
				<template v-for="(item,index) in courseList">
					<div class="courseClass1">
						<span>{{item.name}}</span>
					</div>
					<div v-for="(item2,index2) in item.son" @click="selectCate(item2)" :title="item2.name" class="m-video-right-item" :class="{'m-video-item-active':params.question_cate_id == item2.id}">
						<div class="u-flex">
							<span class="m-video-right-item-text u-line-1">{{item2.name}}</span>
						</div>
					</div>
				</template>
			</el-scrollbar>
		</div>
	</div>
</template>

<script>
	import {
		to_link
	} from "@/router/api"
	import {
		getExaminationsPaper,
		toTheTopic,
		theirPapers,
		answerToPractice,
		collectQuestions,
		delPracticeRecord,
		getQuestionCate
	} from "@/api/center.js"
	var oneFun = null
	export default {
		data() {
			return {
				show:false,
				title:'',
				type:'',
				  
				loading: false,
				isOver: false,
				chengji: 0,
				test_record_id: '',
				info: {},
				list: [],
				currentData: {},
				current: 0,
				count: 0,
				currentCount: 0,
				isMaySubmit: false,

				course_id: '',
				params: {
					page: 1,
					pageNum: 1,
					question_cate_id:'',
					course_id: ''
				},
				courseList: []
			}
		},
		mounted() {
			console.log(this.$route);
			this.params.course_id = this.$route.query.course_id
			this.course_id = this.$route.query.course_id
			this.params.question_cate_id = this.$route.query.type_id
			this.getInfo2()
			this.getCate()
		},
		methods: {
			to_link,
			showAnswer(){
				this.currentData.isShow = true
				this.$forceUpdate()
			},
			selectCate(item){
				if(item.id == this.params.question_cate_id) return false;
				this.params.question_cate_id = item.id
				this.params.page = 1
				this.getInfo2()
			},
			async getCate() {
				let res = await getQuestionCate({
					course_id: this.course_id
				})
				if (res.code == 200) {
					this.courseList = res.data
				} else {
					this.$message.error(res.message)
					this.$router.back()
				}
			},
			resetQuestion() {
				this.$confirm('确认重新答题？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					let res = await delPracticeRecord({
						course_id: this.course_id,
						question_cate_id:this.params.question_cate_id
					})
					if (res.code == 200) {
						this.$message.success('重置成功')
						this.params.page = 1
						this.getInfo2()
					} else {
						this.$message.error(res.message)
					}
				}).catch(() => {})

			},
			collect() {
				collectQuestions({
					questions_id: this.currentData.id
				}).then(res => {
					if (res.code == 200) {
						this.currentData.is_collect = this.currentData.is_collect == 1 ? 0 : 1
						this.$message.success(res.message)
					} else {
						this.$message.error(res.message)
					}
				})
			},
			formatArr(data) {
				if (Object.prototype.toString.call(data).slice(8, -1) == 'Array') {
					return data.join(',')
				} else {
					return data
				}
			},
			//提交试卷
			// async submit(){
			// 	if(oneFun == 'loading') return false;
			// 	oneFun = 'loading'
			// 	setTimeout(()=>{
			// 		oneFun = null
			// 	},500)
			// 	let res = await theirPapers({test_record_id:this.test_record_id})
			// 	if(res.code == 200){
			// 		this.$message.success(res.message)
			// 		this.chengji = res.score
			// 		setTimeout(()=>{
			// 			this.isOver = true
			// 		},200)
			// 	}else{
			// 		this.$message.error(res.message)
			// 	}
			// },
			beforeQuestion() {
				if (this.params.page == 1) return false;
				this.params.page -= 1
				// this.currentData = {}
				this.getInfo()
			},
			//最后一道题
			nextQuestion2() {
				if (this.params.page >= this.count && this.currentData.isAnswer) return false;
				if (this.currentData.type == 3) {
					//多选
					if (this.currentData.answerList.length == 0) {
						this.$message.error('请选择选项')
						return false;
					}
				} else if(this.currentData.type == 1 || this.currentData.type == 2) {
					//单选判断
					if (!this.currentData.answerList) {
						this.$message.error('请选择选项')
						return false;
					}
				}else if(this.currentData.type == 4){
					//简答判断
					if (!this.currentData.answerList) {
						this.$message.error('请填写答案')
						return false;
					}
				}
				this.checkQuestion()
			},
			//下一题
			nextQuestion() {
				if (this.current + 1 >= this.count) return false;

				if (oneFun == 'loading') return false;
				oneFun = 'loading'
				setTimeout(() => {
					oneFun = null
				}, 500)

				if (this.currentData.isAnswer) {
					this.current += 1
					this.currentData = {}
					this.currentData = this.list[this.current]
					return false
				}

				if (this.currentData.type == 3) {
					//多选
					if (this.currentData.answerList.length == 0) {
						this.$message.error('请选择选项')
						return false;
					}
				}else if(this.currentData.type == 1 || this.currentData.type == 2) {
					//单选判断
					if (!this.currentData.answerList) {
						this.$message.error('请选择选项')
						return false;
					}
				}else if(this.currentData.type == 4){
					//简答判断
					if (!this.currentData.answerList) {
						this.$message.error('请填写答案')
						return false;
					}
				}
				this.checkQuestion()
			},
			//判断题题目是否正确
			async checkQuestion() {
				let answer = this.currentData.type == 3 ? this.currentData.answerList.join(',') : this.currentData
					.answerList
				let params = {
					questions_id: this.currentData.id,
					answer: answer,
					course_id: this.course_id
				}
				var that=this;
				toTheTopic(params).then(res=>{
					if (res.code == 200) {
						let time_msg = this.params.page == this.count ? '' : ',3秒后进入下一题'
						if (res.is_true) {
							 this.$message.success('回答正确')
							//that.type="success";
							//that.title='回答正确';
							 
						} else {
							that.type="error";
							 this.$message.error('回答错误')
							//that.title='回答错误';
						} 
						that.show=true; 
						this.currentData.analysis = res.data.analysis
						this.currentData.treuAnswer = res.data.answer
						this.currentData.isAnswer = true
					
						if (this.params.page < this.count) {
							//不是最后一道题的操作
							this.params.page += 1
							this.currentData = {}
							this.getInfo()
							// setTimeout(()=>{
							// 	this.params.page += 1
							// 	this.currentData = {}
							// 	this.getInfo()
							// },3000)
						} else {
							this.isMaySubmit = true
						}
					} else {
						this.$message.error(res.message)
					}
					 
				})
				
			},
			async getInfo() {
				this.loading = true
				let _time = setTimeout(() => {
					this.loading = false
					 
				}, 500)
				let res = await answerToPractice(this.params)
				clearTimeout(_time)
				this.loading = false
				if (res.code == 200) {
					let list = res.data || []
					list.forEach(item => {
						item['isAnswer'] = false
						item['isShow'] = false
						item['answerList'] = item.type == 3 ? [] : ''
						// item['treuAnswer'] = item.type == 3 ? [] : ''
						// item['analysis'] = ''
					})
					if (list.length > 0) {
						this.currentData = list[0]
						console.log(this.currentData);
					}
					this.count = res.count || 0
					this.currentCount = res.user_count == 0 ? 1 : res.user_count + 1
				 
					let _time = setTimeout(() => {
					 
						 this.show=false
					}, 500)
	
				} else if (res.code === 0) {
					this.currentData = {}
					this.$message.error(res.message)
				} else {
					this.$message.error(res.message)
				}
			},
			async getInfo2() {
				this.loading = true
				let params = {}
				Object.assign(params, this.params)
				params.is_first = 1
				let res = await answerToPractice(params)
				setTimeout(() => {
					this.loading = false
				}, 500)
				if (res.code == 200) {
					let list = res.data || []
					list.forEach(item => {
						item['isAnswer'] = false
						item['isShow'] = false
						item['answerList'] = item.type == 3 ? [] : ''
						// item['answer'] = item.type == 3 ? [] : ''
						// item['analysis'] = ''
					})
					if (list.length > 0) {
						this.currentData = list[0]
						console.log(this.currentData);
					}
					if(params.is_first == 1){
						this.params.page = res.page || 1
					}
					
					this.count = res.count
					this.currentCount = res.user_count == 0 ? 1 : res.user_count + 1

				} else if (res.code === 0) {
					this.currentData = {}
					this.$message.error(res.message)
				} else {
					this.$message.error(res.message)
				}
			}
		}
	}
</script>

<style lang="scss" scoped>

	.isErr{
		background-color: red;
		padding: 20px;
		width:100%;
			text-align: center;
		line-height: 30px;
		color:#fff;
	}
	.isSucc{
			padding: 20px;
		background-color: #55f255;
 		text-align: center;
		line-height: 30px;
		color:#fff;
	}
	
	
	.m-font1 {
		font-size: 14px;
		color: #474747;
		line-height: 14px;
	}
	.m-font-html{
		font-size: 14px;
		color: #474747;
		line-height: 20px;
	}

	.m-font2 {
		font-size: 14px;
		color: #F8A310;
		line-height: 14px;
	}

	.m-con-img {
		width: 800px;
		margin-top: 15px;

		img {
			max-width: 800px;
			height: auto;
		}
	}

	.m-title-label {
		color: red;
	}

	.m-right-title {
		display: flex;
		position: relative;
		height: 64px;
		line-height: 20px;
		padding-top: 32px;
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #000000;
		padding-left: 38px;
		border-bottom: 1px solid #EEEEEE;
		box-sizing: border-box;

		.m-right-title-btn {
			position: absolute;
			top: 25px;
			right: 46px;
		}
	}

	.m-con {
		padding: 30px 30px;
		p{
			font-size: 16px;
			font-family: '宋体';
		}
		.m-con-font1 {
			font-size: 14px;
			color: #474747;
			line-height: 22px;
		}

		.m-btn {
			width: 110px;
			height: 33px;
			background: #FFFFFF;
			border: 1px solid #DCDCDC;
			text-align: center;
			line-height: 33px;
			font-size: 14px;
			color: #4D4D4D;
			cursor: pointer;
		}

		.m-btn2 {
			width: 87px;
			height: 33px;
			background: #E2E2E2;
			border: 1px solid #DCDCDC;
			text-align: center;
			line-height: 33px;
			font-size: 14px;
			color: #4D4D4D;
			cursor: pointer;
		}

		.m-dis {
			cursor: no-drop;
			opacity: 0.6;
		}

	}

	.m-answer {
		display: flex;
		width: 880px;
		padding: 24px 0 24px 24px;
		border: 1px solid #D2D2D2;

		.m-btn3 {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 36px;
			height: 100px;
			background: #FF8D31;
			border-radius: 3px 3px 3px 3px;
			writing-mode: vertical-lr;
			writing-mode: tb-lr;
			letter-spacing: 10px;
			color: #fff;
			font-size: 16px;
			cursor: pointer;
		}

		.m-answer-list {
			display: flex;
			flex-wrap: wrap;
			width: 800px;

			.m-answer-item {
				width: 33px;
				height: 33px;
				background: #F1F1F1;
				border: 1px solid #D2D2D2;
				border-radius: 3px;
				font-size: 15px;
				color: #D6102A;
				text-align: center;
				line-height: 33px;
				cursor: pointer;
				margin-right: 6px;
				margin-bottom: 10px;
			}
		}
	}

	.m-type-list {
		display: flex;
		flex-direction: column;
		padding: 16px 16px 24px 24px;
		width: 300px;
		flex-grow: 0;
		flex-shrink: 0;

		.m-video-right-item {
			display: flex;
			height: 44px;
			justify-content: space-between;
			align-items: center;
			cursor: pointer;
			border-bottom: 1px solid #eee;
			padding-left: 18px;

			.m-video-right-item-text {
				width:240px;
				font-size: 14px;
				color: #2D2D2D;
			}

			.m-video-right-item-time {
				display: flex;
				align-items: center;
				flex: 1;
				justify-content: flex-end;
				font-size: 14px;
				color: #838383;
			}
		}

		.m-video-item-active {
			.m-video-right-item-text {
				color: #D6102A !important;
			}

			.m-video-right-item-time {
				color: #D6102A !important;
			}
		}
	}
	.courseClass1 {
		display: flex;
		align-items: center;
		position: relative;
		height: 44px;
		background: #f2f2f2;
		color: #d6102a;
		font-size: 16px;
		padding-left: 16px;
	
		&::before {
			content: '';
			width: 3px;
			height: 25px;
			background: #1b87ef;
			border-radius: 3px;
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
		}
	}
</style>
<style lang="scss">
	.m-con {
		font-weight: 500;
		.el-radio__input.is-checked .el-radio__inner {
			border-color: #F8A310;
			background: #F8A310;
		}
		.el-radio{
		 	display: flex;
		}

		.el-checkbox{
			display: flex;
		}

		.el-radio__input {
			white-space: nowrap;
			cursor: pointer;
			outline: 0;
			line-height: 2;
			vertical-align: middle;
		}
		.el-radio__label {
			font-size: 16px;
			font-family: '宋体';
			white-space: pre-wrap;
			line-height: 24px;
			font-weight: 500;
			color: #000;
		}
		.el-radio__input.is-checked+.el-radio__label {
			color: #F8A310;
		}

		.el-checkbox__input.is-checked+.el-checkbox__label {
			color: #F8A310;
		}

		.el-checkbox__input.is-checked .el-checkbox__inner,
		.el-checkbox__input.is-indeterminate .el-checkbox__inner {
			background-color: #F8A310;
			border-color: #F8A310;
		}
		
	}
	.m-con .el-scrollbar__wrap{
		overflow-x: hidden;
	}
	.m-content-option{
		.el-radio{
			white-space:pre-wrap;
		}
		.el-checkbox {
			white-space:pre-wrap;
		}
	}
</style>
